import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

@Store
export class SearchState extends BaseStore {
  /**
   * Search types
   */
  @Field
  public activeSearch: boolean;

  /**
   *  Get search displayed value
   */
  public get searchState() {
    return this.activeSearch;
  }

  /**
   *  Set search displayed value
   */
  public set searchState(active: boolean) {
    this.activeSearch = active;
  }
}
